import { template as template_9c7e8cd2dbcd47c0abc8fae6ba4a4299 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import capitalize from 'lodash/capitalize';
export default template_9c7e8cd2dbcd47c0abc8fae6ba4a4299(`
  <section class="panel empty-state">
    <img src="{{this.rootURL}}/images/empty-state-activity.svg" alt="" role="none" />

    <div class="empty-state__text">
      <p>{{t
          "pages.organization-learner.activity.empty-state"
          organizationLearnerFirstName=(capitalize @firstName)
          organizationLearnerLastName=(capitalize @lastName)
        }}</p>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
