import { template as template_71362f8be1ee44f3953d9d4547913425 } from "@ember/template-compiler";
import OrganizationPlacesOrCreditInfo from './organization-places-or-credit-info';
import SchoolSessionManagement from './school-session-management';
import UserLoggedMenu from './user-logged-menu';
export default template_71362f8be1ee44f3953d9d4547913425(`
  <div class="topbar">
    <OrganizationPlacesOrCreditInfo @placesCount={{@placesCount}} />
    <SchoolSessionManagement />
    <UserLoggedMenu class="topbar__user-logged-menu" @onChangeOrganization={{@onChangeOrganization}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
