import { template as template_755af3f2f75f4d4e99697d885355d7fc } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_755af3f2f75f4d4e99697d885355d7fc(`
  <div class="table__column--center">
    {{#if (gt @tutorials.length 0)}}
      {{t "pages.campaign-review.table.analysis.column.tutorial-count.value" count=@tutorials.length}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
