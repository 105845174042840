import { template as template_72df0307a6d14ef9915dca8e3faa6128 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_72df0307a6d14ef9915dca8e3faa6128(`
  <p class="screen-reader-only">{{t "charts.participants-by-mastery-percentage.loader"}}</p>
  <div class="participants-by-mastery-percentage--loader placeholder-box" aria-hidden="true"></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
