import { template as template_37eda5074d4646b285267199cbe1bf81 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_37eda5074d4646b285267199cbe1bf81(`
  <PixIndicatorCard
    @title={{if @isTypeAssessment (t "cards.submitted-count.title") (t "cards.submitted-count.title-profiles")}}
    @iconName="inboxIn"
    @color="green"
    @info={{t "cards.submitted-count.information"}}
    @loadingMessage={{t "cards.submitted-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
