import { template as template_4a821829b1994b72ad351d990001c2d0 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { t } from 'ember-intl';
import TargetProfileDetails from '../target-profile-details';
export default template_4a821829b1994b72ad351d990001c2d0(`
  <PixTooltip @id="target-profile-info-tooltip" @position="top-right" @isWide={{true}}>
    <:triggerElement>
      <PixIcon
        ...attributes
        @name="help"
        @plainIcon={{true}}
        tabindex="0"
        aria-label={{t "pages.campaign-settings.target-profile.tooltip"}}
        aria-describedby="target-profile-info-tooltip"
      />
    </:triggerElement>
    <:tooltip>
      <TargetProfileDetails
        @targetProfileDescription={{@targetProfileDescription}}
        @hasStages={{@hasStages}}
        @hasBadges={{@hasBadges}}
        @targetProfileTubesCount={{@targetProfileTubesCount}}
        @targetProfileThematicResultCount={{@targetProfileThematicResultCount}}
      />
    </:tooltip>
  </PixTooltip>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
