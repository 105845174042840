import { template as template_c5ef15aa2ecb4d47acf54bde72fa4d71 } from "@ember/template-compiler";
import onClickOutside from 'ember-click-outside/modifiers/on-click-outside';
export default template_c5ef15aa2ecb4d47acf54bde72fa4d71(`
  {{#if @display}}
    <ul class="dropdown__content" ...attributes {{onClickOutside @close}}>
      {{yield}}
    </ul>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
