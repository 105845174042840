import { template as template_86134e9b1f9c48fda23f739884f28d43 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_86134e9b1f9c48fda23f739884f28d43(`
  <p class="screen-reader-only">{{t "charts.participants-by-stage.loader"}}</p>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
  <div class="participants-by-stage" aria-hidden="true">
    <div class="participants-by-stage__stars--loader placeholder-box"></div>
    <div class="participants-by-stage__values--loader placeholder-box"></div>
    <div class="participants-by-stage__graph--loader placeholder-box"></div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
