import { template as template_56eec7863e2142b6b72c20b35b42dd9b } from "@ember/template-compiler";
import { concat } from '@ember/helper';
export default template_56eec7863e2142b6b72c20b35b42dd9b(`
  <th
    class="{{if @size (concat 'table__column--' @size)}}{{if @align (concat ' table__column--' @align)}}"
    ...attributes
  >
    {{yield}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
