import { template as template_f4a7cd9ab5be469db7962d3bb57aa2d9 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_f4a7cd9ab5be469db7962d3bb57aa2d9(`
  <nav class="mission-navigation__tabs" aria-label={{t "pages.missions.mission.tabs.aria-label"}}>
    <LinkTo @route="authenticated.missions.mission.activities" class="navbar-item">
      {{t "pages.missions.mission.tabs.activities"}}
    </LinkTo>
    <LinkTo @route="authenticated.missions.mission.results" class="navbar-item">
      {{t "pages.missions.mission.tabs.results"}}
    </LinkTo>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
